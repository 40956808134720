import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import BeatLoader from 'react-spinners/BeatLoader'
import { Controller, useForm } from 'react-hook-form'

import GiftCakeImage from '../images/gift-cake.jpg'
import GiftGlassImage from '../images/gift-glass.jpg'

import encode from '../helpers/encode'
import '../styles/pages/gift-voucher.scss'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const GiftVoucherPage = ({ location }) => {
  const [state, setState] = React.useState({})
  const [isSuccess, setIsSuccess] = React.useState(null)
  const { register, handleSubmit, control, errors } = useForm()

  useEffect(() => {
    return () => {
      setIsSuccess(null)
    }
  }, [])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setIsLoading(true)
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setIsSuccess(true)
        // navigate(form.getAttribute('action'), {
        //   state: { isSuccess: true },
        // })
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <SEO title="Gift Voucher" />
      <div className="flex justify-center gift-voucher">
        <div className="flex flex-col max-w-[390px]">
          {isSuccess === true ? (
            <div className="flex flex-col items-center">
              <div className="header-img-tahnkyou">
                <img alt="Voucher Gift" src={GiftGlassImage} />
              </div>
              <div className="flex flex-col max-w-[235px]">
                <div className="text-[#ffffff] mt-10">
                  Thank you for your registration, our team will contact you soon to finalize the
                  payment.
                </div>
                <Link to="/" className="custom-btn mt-10">
                  OK
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="header-img">
                <img alt="Voucher Gift" src={GiftCakeImage} />
              </div>
              <div className="flex justify-items-center w-[262px] mx-auto mt-6 borde-1">
                <form
                  name="Gift Voucher"
                  method="post"
                  action="/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full"
                >
                  <input type="hidden" name="form-name" value="Gift Voucher" />
                  <input type="hidden" name="title" value="Gift Voucher" />
                  <p hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                  </p>
                  <p className="title">
                    Love at first bite
                    <br />
                    at Tuya!
                  </p>
                  <div className="mt-8">
                    <TextField
                      label="Your name"
                      name="name"
                      onChange={handleChange}
                      error={Boolean(errors.name)}
                      helperText={errors && errors.name && errors.name.message}
                      inputRef={register({
                        required: 'This field is required',
                        pattern: {
                          value: /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
                          message: 'Please enter valid name',
                        },
                      })}
                    />
                  </div>
                  <div className="mt-4 md:mt-6">
                    <TextField
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      error={Boolean(errors.email)}
                      helperText={errors && errors.email && errors.email.message}
                      inputRef={register({
                        required: 'This field is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Please enter valid email address',
                        },
                      })}
                    />
                  </div>
                  <div className="mt-4 md:mt-6">
                    <TextField
                      label="Phone number"
                      name="phone"
                      onChange={handleChange}
                      error={Boolean(errors.phone)}
                      helperText={errors && errors.phone && errors.phone.message}
                      inputRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </div>
                  <div className="mt-4 md:mt-6">
                    <TextField
                      label="Enter value"
                      name="amount"
                      onChange={handleChange}
                      error={Boolean(errors.amount)}
                      helperText={errors && errors.amount && errors.amount.message}
                      inputRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </div>

                  <div className="text-center mt-12">
                    <button
                      disabled={isLoading}
                      style={{
                        fontSize: 13,
                        letterSpacing: '1.86px',
                        width: '100%',
                        height: 50,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        border: 'solid 1px #3FA5C6',
                      }}
                    >
                      {!isLoading ? (
                        'GET YOUR GIFT CARD'
                      ) : (
                        <BeatLoader color="white" loading={true} size={16} />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default GiftVoucherPage
